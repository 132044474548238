.TextPage-Div {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TextPage-Content-Div {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.TextPage-Video {
    width: 100%;
    min-height: 80vh;
    max-height: 110vh;
    object-fit: contain;
    object-position: center;
}

.TextPage-Video-Div {
    padding-bottom: 30px;
}

.TextPage-Text-Div {
    padding: 30px;
}

.TextPage-Title {
    font-size: 25px;
    font-weight: bold;
}

.TextPage-Paragraph {
    font-size: 18px;
}

.TextPage-Gallery-Image {
    width: 100%;
    height: 300px;
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
}

.TextPage-Row {
    padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    .TextPage-Text-Div {
        padding: 0px;
    }
    .TextPage-Paragraph {
        font-size: 16px;
    }
}