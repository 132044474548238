.Navbar-Logo-H2 {
    color: black;
    font-size: 25px;
    font-weight: bold;
    margin-top: 1px;
    margin-bottom: 1px;
    line-height: 30px;
}

.Landing-Custom-Section-Title-Navbar {
    font-family: "Mistral";
    font-size: 25px;
}

.Navbar-Logo-H3 {
    color: #62842D;
    font-size: 22px;
    font-weight: bold;
    margin-top: 1px;
    margin-bottom: 1px;
}

.Navbar-Logo-Div {
    padding: 5px;
    margin-bottom: 15px;
}

.Navbar-Link-Text {
    color: black;
    font-size: 16px;
    padding-right: 4px;
    padding-left: 4px;
    margin-right: 6px;
}

.Navbar-Vertical-Line {
    border-right: medium solid black;
}

.Navbar-Logo-Image {
    max-height: 50px;
    max-width: 80px;
    object-fit: contain;
    object-position: center;
}

.Navbar-Button {
    color: white;
    background-color: black;
    border-color: black;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}

.Navbar-Button:hover {
    color: white;
    background-color: black;
    border-color: black;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}

.Navbar-Button-Inverted {
    color: black;
    background-color: white;
    border-color: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}

.Navbar-Button-Inverted:hover {
    color: black;
    background-color: white;
    border-color: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}


.Navbar-Link-Text-Small {
    font-size: 20px;
    text-align: center;
    color: black;
}

.Navbar-Logo-H2-Small {
    color: #62842D;
    font-size: 30px;
    font-weight: bold;
    margin-top: 1px;
    margin-bottom: 1px;
    line-height: 30px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .Navbar-Large {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .Navbar-Small {
        display: none;
    }
}