.Reviews-Image {
    width: 100%;
    height: 500px;
    object-position: center;
    object-fit: contain;
}

.Review-Container {
    color: white;
    background-color: black;
}


.Review-Text {
    max-width: 400px;
}

.Review-Text-Left {
    font-size: 18px;
    text-align: justify;
    padding-right: 30px;
}

@media only screen and (max-width: 600px) {
    .Review-Text-Left {
        max-width: 300px;
        font-size: 18px;
    }

    .Review-Text {
        padding-top: 20px;
    }
}

.ant-carousel .slick-prev{
    color: white;
    font-size: unset;
    left: 5%;
    z-index: 1;
    font-size: 35px;
  }

.ant-carousel .slick-next {
    color: white;
    font-size: unset;
    right: 5%;
    z-index: 1;
    font-size: 35px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: white;
}