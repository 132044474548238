.Footer-Div {
    padding-left: 20px;
    padding-right: 20px;
    background-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Footer-Row {
    min-height: 100px;
}

.Footer-Link-Text {
    color: white;
    font-size: 16px;
    padding-right: 4px;
    padding-left: 4px;
    margin-right: 6px;
}

.Footer-Vertical-Line {
    border-right: medium solid white;
}

.Footer-Follow {
    color: white;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
    line-height: 20px;
}

.Footer-Social {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .Footer-Follow-Div {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .Footer-Div {
        padding-top: 15px;
    }

    .Footer-Button-Col {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}