.Landing-Div {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Landing-Content-Div {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.Landing-Reviews {
    padding-top: 50px;
    padding-bottom: 30px;
}

.Landing-Custom-Section-Title {
    padding-top: 30px;
    text-align: center;
    font-family: "Mistral";
    font-size: 30px;
}

.Landing-Custom-Section-Paragraph {
    padding-bottom: 50px;
    font-size: 17px;
    text-align: justify;
}

.Landing-Video {
    width: 100%;
    max-width: 100vw;
    max-height: 110vh;
    object-fit: contain;
    object-position: center;
}

.Landing-Video-Div {
    padding-bottom: 30px;
}