.FeatureCard-Image {
    width: 100%;
    height: 300px;
    object-fit: contain;
    object-position: center;
}

.FeatureCard-Title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.FeatureCard-Description {
    font-size: 16px;
    text-align: justify;
    padding: 15px;
}

.FeatureCard-Subtitle {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
}

.FeatureCard-Div {
    background-color: white;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
    height: 100%;
}